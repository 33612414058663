<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <!-- 企业简介 -->
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
               <p class="back" @click="$router.push('/wordNav')">返回</p>
                <img src="@/assets/wordOp/Intitle.png" alt="" class="title">
                <div class="ct_box">
                    <div class="form_box">
                        <div class="text_box flex">
                            <p class="title_1">企业名称：</p>
                            <el-input v-model="form.name" placeholder="请录入企业名称"></el-input>
                        </div>
                        <div class="select_box flex">
                            <p class="title_1">从事行业：</p>
                            <el-select v-model="form.type" placeholder="请录入从事行业">
                                <el-option v-for="(item, index) in selectList" :key="index" :value="item">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="text_box flex">
                            <p class="title_1">业务描述：</p>
                            <el-input v-model="form.desc" placeholder="请录入您公司对外业务"></el-input>
                        </div>
                        <div class="text_box flex">
                            <p class="title_1">其他描述：</p>
                            <el-input maxlength="50" v-model="form.qtherDesc" placeholder="如：成立时间、所获荣誉等"></el-input>
                            <p>{{ form.qtherDesc.length }}/50</p>
                        </div>
                        <button class="create_but" @click="FileData">生成内容</button>
                        <el-input resize="none" readonly type="textarea" :rows="13"
                            v-model="contentData.output_content">
                        </el-input>
                        <button class="copy" @click="copy()">复制内容</button>
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '../../components/wordNav.vue'
export default {
    name: '',
    created() { },
    mounted() {
        this.getUserInfo()
    },
    components: {
        wordNav
    },
    data() {
        return {
            name: '企业管理',
            form: {
                type: '', //从事行业
                name: '', //企业名称
                desc: '', //主营业务
                qtherDesc: '' // 关键字
            },
            selectList: [
                '互联网/AI',
                '电子/电器/通信',
                '产品',
                '运营/客服',
                '销售',
                '人力/行政/法务',
                '财务/审计/税务',
                '生产制造',
                '生活服务/零售',
                '餐饮',
                '酒店/旅游',
                '教育培训',
                '设计',
                '房地产/建筑',
                '影视传媒',
                '市场/公关/广告',
                '物流/仓储/司机',
                '采购/贸易',
                '汽车',
                '医疗器械',
                '金融',
            ],
            fullscreenLoading: false,
            contentData: {}, //内容
            aiNumShow: false,
            aiPoupShow: false,
        }
    },
    methods: {
        getUserInfo() {
            this.curlGet('/api/user/info').then((res) => {
                if (res.data.code) {
                    this.$user_info = res.data.data
                    this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                }
            })
        },
        copy() {
            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(this.contentData.output_content).then(
                (e) => {

                    return this.$message({
                        message: '复制成功',
                        type: 'success',
                        offset: 80
                    });
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                        offset: 80
                    });
                }
            );
        },
        FileData() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入主营业务',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }
            // if (this.form.kesWord == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true

                // this.postData()
            }
        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true

            this.curlPost('/file/company_profile', {
                name: this.form.name,
                type: this.form.type,
                // business: this.form.business,
                desc: this.form.desc,
                qtherDesc: this.form.qtherDesc,
                // kesWord: this.form.kesWord,
                mobile: this.$user_info.mobile,

            }).then(res => {
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '../../scss/EnterpriseProfile.scss'
</style>